<template>
  <div class="qr-email">
    <input
      v-model="email"
      class="qr-email__input"
      placeholder="Email для чека"
      type="email"
    />
    <div class="qr-email__rows">
      <div class="qr-email__row" @click="onCheckboxClick('isAgreeService')">
        <QrCheckbox
          class="qr-email__checkbox"
          :checked="value.isAgreeService"
          icon="checkGrey"
          @change="onCheckboxClick('isAgreeService')"
        />
        <div class="qr-email__text">
          Я хочу взять на себя транзакционные издержки (₽ 10), чтобы сотрудник
          получил полную сумму чая
        </div>
      </div>
      <div class="qr-email__row" @click="onCheckboxClick('isAgreeLicence')">
        <QrCheckbox
          class="qr-email__checkbox"
          :checked="value.isAgreeLicence"
          icon="checkGrey"
          @change="onCheckboxClick('isAgreeLicence')"
        />
        <div class="qr-email__text">
          Согласен с условиями Пользовательского соглашения и Политикой
          обработки персональных данных
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { values } from 'lodash'
import QrCheckbox from './QrCheckbox'

export default {
  components: {
    QrCheckbox,
  },
  props: {
    value: {
      type: Object,
      require: true,
    },
  },
  computed: {
    email: {
      get() {
        return this.value.email
      },
      set(value) {
        this.$emit('input', {
          ...this.value,
          email: value,
        })
      },
    },
  },
  methods: {
    onCheckboxClick(property) {
      this.$emit('input', {
        ...this.value,
        [property]: !this.value[property],
      })
    },
  },
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.qr-email {
  padding: 30px 16px;
  margin-bottom: 16px;
  margin-left: -16px;
  margin-right: -16px;
  padding-left: 16px;
  padding-right: 16px;
  background: $black;
  border-radius: 8px;

  &__input {
    width: 100%;
    height: 48px;
    background: transparent;
    border: 1px solid rgba(102, 102, 102, 1);
    outline: none;
    border-radius: 8px;
    color: $white;
    text-align: center;
    @include font(18, 21, 400);
    margin-bottom: 24px;

    &::placeholder {
      color: rgba(148, 148, 148, 1);
      opacity: 1; /* Firefox */
    }

    &::-ms-input-placeholder {
      /* Edge 12 -18 */
      color: rgba(148, 148, 148, 1);
    }
  }

  &__rows {
    @include flex(column, flex-start, center);
    gap: 12px;
  }

  &__row {
    width: 100%;
    @include flex(row, flex-start, center);
    text-align: left;
    gap: 10px;
  }

  &__checkbox {
    width: 25px;
  }

  &__text {
    @include font(10, 12, 400);
    color: rgba(148, 148, 148, 1);
  }
}
</style>
