<template>
  <div class="qr-checkbox" @click.stop="setChecked">
    <img v-if="checked" :src="`/img/${icon}.svg`" />
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    checked: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: 'checkGold'
    }
  },
  methods: {
    setChecked() {
      this.$emit('change', !this.checked)
    },
  },
}
</script>

<style lang="scss" scoped>
.qr-checkbox {
  width: 22px;
  height: 22px;
  background-color: $black;
  border: 1px solid rgba(102, 102, 102, 1);
  border-radius: 6px;
  @include flex(row, center, center);
}
</style>
