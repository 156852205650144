<template>
  <div class="s-category-dishes" :class="{ '-short': openInfo }">
    <img
      src="/img/backWhite.png"
      srcset="/img/backWhite@3x.png 3x /img/backWhite@2x.png 2x /img/backWhite.png 1x"
      class="s-category-dishes-back"
      v-if="openInfo"
      @click="openCloseInfo"
    />
    <img
      v-else-if="!drinks && !historyMode"
      key="home"
      width="24px"
      height="24px"
      src="/img/iconHome.png"
      srcset="/img/iconHome@3x.png 3x /img/iconHome@2x.png 2x /img/iconHome.png 1x"
      class="s-category-dishes-toplace"
      @click="closeSwipe"
    />
    <img 
        v-if="!deliveryFlow && supportOrders"
        key="iconTableCode"
        class="s-category-dishes__table-code"
        src="/img/call-bell-white.svg"
        @click="enterCode(place.uid)"
      />
    <div class="s-category-dishes-paging">
      <div
        class="s-category-dishes-paging__page"
        v-for="(dish, i) in dishes"
        :key="i"
        :class="{ '-active': i === index }"
      ></div>
    </div>
    <div class="s-category-dishes-slider" :class="{ '-short': openInfo }">
      <div
        class="s-category-dishes-slider__item slider-item"
        v-for="(dish, i) in dishes"
        :id="`slider-item-dish-${dish.uid}`"
        :class="{ '-active': i === index }"
        :key="dish.uid"
        :style="`background-image: url(&quot;${getImage(dish)}&quot;);`"
        v-show="i <= index + 2 && i >= index - 2"
      >
        <!-- <div class="category-name">{{ category.title }}</div> -->
        <div class="backdrop-image"></div>
        <div class="dish-info">
          <div class="dish-info-rate">
            <img
              v-for="(star, i) in dishRate"
              :key="i"
              class="dish-info-rate__star"
              :src="`/img/${star}@3x.png`"
              width="24px"
            />
            <div
              class="dish-info-rate__posts"
              v-if="currentDish && currentDish.comments_number"
            >
              {{ currentDish.comments_number }} {{ $t("publications") }}
            </div>
          </div>
          <div class="dish-info-price">
            {{ currencySymbol }}
            {{ delivery ? dish.delivery_price : dish.price }}
            <span v-if="dish.is_price_per_base_amount">{{amountPerUnitText}}</span>
          </div>
          <div class="dish-info-title">{{ dish.title }}</div>
          <div class="dish-info-stop" v-if="openInfo && !dish.is_active">
            <img
              src="/img/combinedShape.png"
              srcset="/img/combinedShape@3x.png 3x /img/combinedShape@2x.png 2x /img/combinedShape.png 1x"
            />
            <div class="stop-text">
              {{ dish.on_stop_title || $t("onstop") }}
            </div>
          </div>
          <template v-if="!openInfo && !dish.is_active">
            <img
              src="/img/combinedShape.png"
              srcset="/img/combinedShape@3x.png 3x /img/combinedShape@2x.png 2x /img/combinedShape.png 1x"
              class="dish-info-stop-large-image"
            />
            <div class="dish-info-stop-large-text">
              {{ dish.on_stop_title || $t("onstop") }}
            </div>
          </template>
          <img
            v-if="!openInfoOnly"
            v-show="!openInfo"
            class="dish-info-icon"
            src="/img/iconStatus.png"
            width="24px"
            srcset="/img/iconStatus@3x.png 3x /img/iconStatus@2x.png 2x /img/iconStatus.png 1x"
            @click="openCloseInfo"
          />
          <div
            class="dish-info-back"
            style="background-image: url(/img/backWhite@3x.png)"
            v-if="dishInfoBackClose || !openInfoOnly"
            v-show="openInfo"
            @click="openCloseInfo"
          ></div>
        </div>
      </div>
      <div
        class="s-category-dishes-slider__nav -prev"
        @click="goprev"
        :class="{ '-stop': !currentDish || !currentDish.is_active }"
      ></div>
      <div
        class="s-category-dishes-slider__nav -next"
        @click="gonext"
        :class="{ '-stop': !currentDish || !currentDish.is_active }"
      ></div>
    </div>
    <!-- <div class="s-category-dish__translate" v-if="currentDish && openInfo && languages.length > 1" @click="translate">{{ $t('translate') }}</div> -->
    <div class="s-category-dish__info" v-if="currentDish && openInfo">
      <div class="m-list">
        <div class="m-list-item" v-if="!currentDish || !currentDish.is_active">
          <img
            style="margin-top: 4px"
            width="24px"
            height="auto"
            src="/img/icStop.png"
            srcset="/img/icStop@3x.png 3x /img/icStop@2x.png 2x /img/icStop.png 1x"
            class="m-list-item__icon"
          />
          <div class="m-list-item__text">{{ $t("onstop") }}</div>
        </div>
        <div class="m-list-item" v-if="!hideModifiers && modifiers" @click="openModifiers">
          <img
            width="24px"
            height="auto"
            src="/img/iconModify.png"
            srcset="/img/iconModify@3x.png 3x /img/iconModify@2x.png 2x /img/iconModify.png 1x"
            class="m-list-item__icon"
          />
          <div class="m-list-item__text">{{ $t("modifiers.modifiers") }}</div>
          <img class="m-list-item__arrow" src="/img/arrow-right-white.svg" />
        </div>
        <div class="m-list-item" v-if="currentDish.ingredients">
          <img
            width="24px"
            height="auto"
            src="/img/icSostav.png"
            srcset="/img/icSostav@3x.png 3x /img/icSostav@2x.png 2x /img/icSostav.png 1x"
            class="m-list-item__icon"
          />
          <div class="m-list-item__text">{{ currentDish.ingredients }}</div>
        </div>
        <div class="m-list-item" v-if="currentDish.calories">
          <img
            width="24px"
            height="auto"
            src="/img/icWeight.svg"
            class="m-list-item__icon"
          />
          <div class="m-list-item__text">{{ currentDish.calories }}</div>
        </div>
        <div class="m-list-item" v-if="currentDish.cooking_time">
          <img
            width="24px"
            height="auto"
            src="/img/icAccessTime.svg"
            class="m-list-item__icon"
          />
          <div class="m-list-item__text">{{ currentDish.cooking_time }}</div>
        </div>
        <div class="m-list-item" v-if="currentDish.recipe">
          <img
            width="24px"
            height="auto"
            src="/img/icRecept.svg"
            class="m-list-item__icon"
          />
          <div class="m-list-item__text">{{ currentDish.recipe }}</div>
        </div>
        <div class="m-list-item" v-if="currentDish.description">
          <img
            width="24px"
            height="auto"
            src="/img/icInfoFood.png"
            srcset="/img/icInfoFood@3x.png 3x /img/icInfoFood@2x.png 2x /img/icInfoFood.png 1x"
            class="m-list-item__icon"
          />
          <div class="m-list-item__text">{{ currentDish.description }}</div>
        </div>
      </div>
    </div>
    <div class="s-category-dish__comments" v-if="!hideComments && currentDish && openInfo">
      <router-link
        tag="div"
        class="s-category-dish__comments-comment"
        v-for="comment in comments"
        :key="comment.uid"
        :style="`background-image: url(&quot;${comment.photo_uri}&quot;);`"
        :to="`/itemposts/${currentDish.uid}`"
      >
      </router-link>
    </div>
    <div class="s-category-dishes__actions" v-if="currentDish && openInfo && !hideButtons">
      <div
        v-if="place.enable_basket"
        class="add-to-order"
        @click="swipeUpDishHandler(true)"
      >
        <img src="/img/add-to-order.svg" class="add-to-order__image" alt="">
        <div class="add-to-order__text">{{ $t('order.addToOrder') }}</div>
      </div>
      <img
        v-if="orderLen || $checkins.length"
        key="notEmpty"
        class="m-cir-icon-button check-order"
        width="30px"
        height="30px"
        src="/img/iconCheck@3x.png"
        @click="openOrder"
      />
      <img
        v-else
        width="30px"
        height="30px"
        src="/img/iconCheckEmpty@3x.png"
        key="empty"
        class="m-cir-icon-button check-order"
        @click="openOrder"
      />
      <div class="check-order-counter" v-if="orderLen">{{ orderLen }}</div>
    </div>
    <DishModifiersModal
      v-if="!hideModifiers && modifiers && showModifiersModal"
      :modifiers="modifiers"
      :currencySymbol="currencySymbol"
      @closeModifiers="showModifiersModal = false"
      :addedModifiers="dishModifiers"
    />
    <div class="s-category-dishes__order-comment" v-if="orderComment && currentUid === startAt">
      <div class="s-category-dishes__order-comment-header">
        Комментарий
      </div>
      <div class="s-category-dishes__order-comment-text">
        {{ orderComment }}
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import callWaiterMixin from '@/mixins/callWaiterMixin'
import CategoryModal from "./CategoryModal";
import Icon from "./UI/Icon";
import DishModifiersModal from "./DishModifiersModal";

export default {
  mixins: [ callWaiterMixin ],
  props: {
    category: {
      type: Object,
      required: true,
    },
    currencySymbol: {
      type: String,
      default: "$",
    },
    openInfoOnly: {
      type: Boolean,
      default: false,
    },
    startAt: {
      type: [Number, String],
      default: 0,
    },
    drinks: {
      type: Boolean,
      default: false,
    },
    currentCategoryUid: {
      type: String,
      default: "",
    },
    singleCategory: {
      type: Boolean,
      default: false,
    },
    hideButtons: {
      type: Boolean,
      default: false
    },
    hideModifiers: {
      type: Boolean,
      default: false
    },
    hideComments: {
      type: Boolean,
      default: false
    },
    orderComment: {
      type: String,
      default: ''
    },
    dishInfoBackClose: {
      type: Boolean,
      default: false
    },
    placeUid: {
      type: String,
      default: ''
    },
    placeType: {
      type: String,
      default: ''
    }
  },
  components: {
    Icon,
    CategoryModal,
    DishModifiersModal,
  },
  data() {
    const startAt = this.startAt;
    return {
      index: typeof startAt === "string" ? 0 : startAt,
      openInfo: this.openInfoOnly,
      comments: [],
      dishesFromDB: [],
      showTranslate: false,
      order: window.ordersStore.orderItems,
      showModifiersModal: false,
      dishModifiers: [],
      places: this.$PS.data,
      deliveryFlow: window.ordersStore.deliveryFlow
    };
  },
  computed: {
    ...mapState("user", {
      delivery: (state) => state.delivery,
    }),
    startAtUid() {
      return typeof this.startAt === "string";
    },
    historyMode() {
      return this.$route.query.history;
    },
    orderLen() {
      return this.order.length;
    },
    place() {
      const category = this.category?.category || this.category;
      const place_uid = this.placeUid || category?.place_uid;
      return this.places.find((pl) => pl.uid === place_uid);
    },
    languages() {
      return this.place?.languages || [];
    },
    dishes() {
      const dishes = this.category?.dishes || this.dishesFromDB;
      return dishes.slice().sort((a, b) => {
        const [aSort, bSort] = [a.sort_index, b.sort_index];
        if (aSort > bSort) {
          return 1;
        } else if (aSort < bSort) {
          return -1;
        }
        return 0;
      });
    },
    currentDish() {
      return this.dishes[this.index];
    },
    modifiers() {
      const modifiers = this.currentDish?.modifiers;
      if (modifiers && modifiers.length) return modifiers;
      return null;
    },
    dishRate() {
      const rating = this.currentDish?.rating || 0;
      const out = [];
      for (let i = 1; i < 6; i++) {
        const range = rating - i;
        if (range >= 0 || range > -0.5) {
          out.push("starDishRateGold");
        } else if (range > -1) {
          out.push("starDishRateGoldHalf");
        } else {
          out.push("starDishRateNone");
        }
      }
      return out;
    },
    currentUid() {
      return (this.dishes || [])[this.index]?.uid;
    },
    supportOrders() {
			return this.place?.supported_flows?.orders
		},
    amountPerUnitText() {
      if (!this.currentDish) return ''
      return `${this.$t('dishes.per')} ${this.$tc(`dishes.measures.${this.currentDish.measure_unit}`, Number(this.currentDish.measure_base_amount))}`
    }
  },
  watch: {
    currentDish: {
      immediate: true,
      handler: "getComments",
    },
    dishes: {
      immediate: true,
      handler(val) {
        if (val && val.length) {
          const { startAtUid, startAt } = this;
          if (startAtUid) {
            this.index = val.findIndex((d) => d.uid === startAt);
          }
        }
      },
    },
  },
  created() {
    this.$store.commit('places/SET_SWIPE_MODAL_ACTIVE', true)
    this.$bus.$on("SwipeUpDish", this.swipeUpDishHandler)
    const { menu_item } = this.$route.query
    if (menu_item) {
      this.index = this.dishes.findIndex((dish) => dish.uid === menu_item)
    }
    if (!this.category.dishes) {
      this.getDishes()
    }
  },
  beforeDestroy() {
    this.$store.commit('places/SET_SWIPE_MODAL_ACTIVE', false)
    this.$bus.$off("SwipeUpDish", this.swipeUpDishHandler)
  },
  methods: {
    openModifiers() {
      this.showModifiersModal = true;
    },
    showModificatorsAlert(fromDetails = false) {
      this.$bus.$emit("openContextMenu", {
        config: {
          type: "modal",
          style: "pink",
          title: this.$t(
            `delivery.errors.${
              fromDetails ? "MODIFIERS_REQUIRED_DETAILS" : "MODIFIERS_REQUIRED"
            }.title`
          ),
          subtitle: this.$t(
            `delivery.errors.${
              fromDetails ? "MODIFIERS_REQUIRED_DETAILS" : "MODIFIERS_REQUIRED"
            }.text`
          ),
          icon: fromDetails
            ? "/img/iconModify@3x.png"
            : "/img/iconAttention.svg",
        },
        actions: [
          {
            id: "ok",
            text: this.$t("order.toChoose"),
          },
          {
            id: "cancel",
            text: this.$t("cancel"),
          },
        ],
        resolve: (index) => {
          if (index === 0) {
            this.openModifiers()
          }
        },
        reject: () => ({}),
      });
    },
    showNotClosedCheckinAlert() {
      this.$bus.$emit("openContextMenu", {
        config: {
          type: "modal",
          style: "pink",
          title: this.$t("delivery.errors.HAS_OPEN_CHECKIN.title"),
          subtitle: this.$t("delivery.errors.HAS_OPEN_CHECKIN.text"),
          icon: "/img/iconAttention.svg",
        },
        actions: [
          {
            id: "ok",
            text: "OK",
          },
        ],
        resolve: () => ({}),
        reject: () => ({}),
      });
    },
    anotherPlaceCheckinAlert(place) {
      const { name, uid } = place
      this.$bus.$emit("openContextMenu", {
        config: {
          style: "pink",
          noCancel: true
        },
        actions: [
          {
            id: "ok",
            text: this.$t('order.nowOrderIn')+': '+name,
            nonClicked: true
          },
          {
            id: "goto",
            text: this.$t('order.goTo')+': '+name,
          },
          {
            id: "cancel",
            text: this.$t('toCancelCap'),
          }
        ],
        resolve: (index) => {
          if (index === 1) {
            this.$bus.$emit('GlobalModal/dishesOpen')
            this.$bus.$emit('Home/drop', () => {
              this.$bus.$emit('Home/loadMenu', uid)
            })
            // 
          }
        },
        reject: () => ({}),
      });
    },
    fromDifferentFlowsAlert(deliveryFlow) {
			const erorrSection = deliveryFlow ? 'FROM_DIFFERENT_FLOWS_ORDERS_PREV' : 'FROM_DIFFERENT_FLOWS_DELIVERY_PREV'
			this.$bus.$emit("openContextMenu", {
                config: {
                    type: "modal",
                    style: "pink",
                    title: this.$t(`delivery.errors.${erorrSection}.title`),
                    subtitle: this.$t(`delivery.errors.${erorrSection}.text`),
                    icon: "/img/iconAttention.svg",
                },
                actions: [
                    {
                        id: "ok",
                        text: "OK",
                    },
                ],
                resolve: () => ({}),
                reject: () => ({}),
            });
		},
    swipeUpDishHandler(fromDetails = false) {
      const { currentCategoryUid, currentDish, singleCategory } = this;

      if (!currentDish.is_active) return;
      const tryToAdd = async () => {
        const addedResult = this.addToOrder();
        if (addedResult?.error === "has_unpaid_invoice") {
          this.$bus.$emit('GOM/invoices/unpaidInvoice', addedResult.invoice)
        } else if (addedResult?.error === "checkin_in_another_place") {
          this.anotherPlaceCheckinAlert(addedResult.place);
        } else if (addedResult?.error === "from_different_flows") {
          this.fromDifferentFlowsAlert(addedResult.deliveryFlow)
        } else if (addedResult === "has_checkin") {
          this.showNotClosedCheckinAlert();
        } else if (addedResult === "modificators_required") {
          this.showModificatorsAlert(fromDetails);
        } else {
          this.dishModifiers = [];
          if (!localStorage.getItem('dontAskForComments')) {
            this.$bus.$emit('openContextMenu', {
              config: {
                  type: 'modal',
                  style: 'pink',
                  title: this.$t('order.alertOrderComments'),
                  icon: '/img/billWhiteCircle.svg'
              },
              actions: [
                {
                  id: 'ok',
                  text: 'OK'
                }
              ],
              resolve: () => {
                localStorage.setItem('dontAskForComments', true)
                this.$bus.$emit("alertShow")
              },
              reject: () => {
                localStorage.setItem('dontAskForComments', true)
                this.$bus.$emit("alertShow")
              }
            })
          }
          this.animateAddToOrder(currentDish.uid)
        }
      };
      if (!singleCategory) {
        if (currentDish.category_uid === currentCategoryUid) {
          tryToAdd();
        }
      } else {
        tryToAdd();
      }
    },
    animateAddToOrder(uid) {
      const originNode = document.getElementById(`slider-item-dish-${uid}`)
      const clonedNode = originNode.cloneNode(originNode)
      const wrapper = document.createElement('DIV')
      wrapper.classList.add('s-category-dishes-slider', '-short', '-swiped-up')
      wrapper.appendChild(clonedNode)
      this.$el.appendChild(wrapper)
      window.requestAnimationFrame(() => {
         wrapper.classList.add('-animate-swipe') 
      })
      setTimeout(() => {
        wrapper.remove()
      }, 400)
    },
    getDishes: async function () {
      const dishes = (
        await this.$DB.db
          .collection("menu_items")
          .where("category_uid", "==", this.category.uid)
          .get()
      ).docs.map((doc) => Object.freeze(doc.data()));
      if (dishes && dishes.length) {
        this.dishesFromDB.push(...dishes);
      }
    },
    getComments: async function (dish) {
      this.comments = [];
      this.dishModifiers = [];
      if (!dish) return;
      const { uid, comments_number } = dish;
      if (comments_number) {
        let comments = (
          await this.$DB.db
            .collection("comments")
            .where("item_uid", "==", uid)
            .where("moderation_status", "in", ["approved", "waiting_for"])
            .get()
        ).docs.map((doc) => Object.freeze(doc.data()));
        comments.sort((a, b) => {
          const [aName, bName] = [a.created_at.seconds, b.created_at.seconds];
          if (aName < bName) {
            return 1;
          } else {
            return -1;
          }
        });
        if (this.currentUid === uid) {
          this.comments = comments;
        }
        // .where('moderation_status', '==', 'approved')
      }
    },
    closeSwipe() {
      this.$bus.$emit("closeMenuMode");
    },
    addToOrder() {
      return window.ordersStore.addToOrder({
        dish: JSON.parse(JSON.stringify(this.currentDish)),
        currencySymbol: this.currencySymbol,
        selectedModifiers: this.dishModifiers,
        fromPlaceUid: this.placeUid
      });
    },
    getImage({ photo_uri = "", comment_photo_uri = "" }) {
      if (!photo_uri && comment_photo_uri) {
        return comment_photo_uri;
      }
      if (
        !photo_uri ||
        photo_uri === "https://storage.googleapis.com/mayber-prod.appspot.com/"
      ) {
        return "/img/restaurantPlaceholder.jpg";
      }
      return photo_uri;
    },
    gonext() {
      const len = this.dishes.length,
        index = this.index;
      if (index + 1 === len) {
        return;
      }
      window.requestAnimationFrame(() => {
        this.index++;
      });
    },
    goprev() {
      const index = this.index;
      if (!index) {
        return;
      }
      window.requestAnimationFrame(() => {
        this.index--;
      });
    },
    openCloseInfo() {
      if (this.openInfoOnly) {
        this.$emit("close", this.currentUid);
      } else {
        this.$emit("destroyCard", this.$el);
        window.requestAnimationFrame(() => {
          this.openInfo = !this.openInfo;
          if (!this.drinks) {
            this.$bus.$emit("openInfo", this.openInfo);
          } else {
            this.$emit("openinfo", this.openInfo)
          }
        });
      }
    },
    openOrder() {
      this.$router.replace(`/orders??openFromUid=${this.placeUid}`)
    },
    translate: async function () {
      const currentItem = this.place,
        uid = currentItem.uid;
      const languages = currentItem?.languages || [];
      let lang = "";
      if (languages && languages.length > 1) {
        try {
          lang = await this.$root.langModal.show(languages);
        } catch (e) {
          console.log(e);
        }
        if (lang) {
          localStorage.setItem("langKey:" + uid, lang);
          this.$bus.$emit("translatePlace", uid, lang);
          this.$emit("input", false);
          this.$bus.$emit("GlobalModal/dishesOpen", null);
        }
      }
    },
  },
};
</script>
<style lang="scss">
.m-list-item {
  position: relative;
}
.m-cir-icon-button.check-order {
  border-radius: 0px !important;
}
.m-list-item__arrow {
  @include pos-centered-v;
  right: 22px;
}
.s-category-dish__translate {
  width: 150px;
  height: 36px;
  border: 2px solid $white;
  border-radius: 24px;
  @include pos-centered();
  @include flex(row, center, center);
  z-index: 2;
  color: $white;
  @include font(16, 16, normal);
  text-transform: uppercase;
  background: $black;
}
.s-category-dish__info {
  margin-top: 10px;
  margin-bottom: 70px;
  // height: calc(50% - 70px);
  // overflow: scroll;
}
.s-category-dish__comments {
  @include flex(row, flex-start, flex-start);
  flex-wrap: wrap;
  &-comment {
    width: 33.1%;
    height: 124px;
    // display: inline-block;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 1px;
    &:not(:nth-child(3n)) {
      margin-right: 1px;
    }
  }
}
.s-category-dishes {
  position: relative;
  background: $black;
  height: 100%;

  &.-short {
    overflow-y: scroll;
    overflow-x: hidden;
  }

  &__order-comment {
    position: fixed;
    bottom: 15%;
    width: calc(100% - 110px);
    left: 15px;
    right: 15px;
    padding: 18px 40px;
    background: #252527;
    border: 1.5px solid #4C4C4E;
    border-radius: 20px;
    color: $white;
    @include font(17, 20, 400);
    
    &-header {
      text-transform: uppercase;
      margin-bottom: 6px;
    }
  }

  .m-list {
    background: $black;
    color: $white;
    .m-list-item__text {
      color: $white;
    }
  }

  &-toplace {
    position: fixed;
    top: 25px;
    left: 25px;
    z-index: 99;
  }

  &__actions {
    .add-to-order {
      @include pos-centered-h;
      position: fixed;
      bottom: 30px;
      width: 100px;
      user-select: none;

      &__text {
        @include font(12, 13, 400);
        text-transform: lowercase;
        color: $white;
        margin-top: -5px;
      }
    }

    .check-order {
      position: fixed;
      bottom: 45px;
      right: 15px;
      width: 30px;
      height: 30px;
      user-select: none;
    }

    .check-order-counter {
      position: fixed;
      bottom: 60px;
      right: 10px;
      user-select: none;
      background: red;
      @include font(10, 10);
      color: #ffffff;
      @include flex(row, center, center);
      padding: 5px;
      border-radius: 50%;
      min-width: 10px;
    }
    // position: fixed;
    // width: 100%;
    // bottom: 20px;
    // height: 30px;
    // @include flex(row, flex-start, center);

    // div {
    // 	@include flex(row, center, center);
    // }

    .text-left {
      width: 20%;
      // margin-left: 30px;
      @include font(30, 30);
    }

    .text-center {
      width: 60%;
      @include font(14, 32);
      text-transform: uppercase;
    }

    .text-right {
      width: 20%;
      position: relative;

      &__counter {
        position: absolute;
        top: -20px;
        right: 15px;
        background: red;
        min-width: 10px;
        color: #ffffff;
        padding: 5px;
        border-radius: 50%;
        @include font(10, 10);
      }
    }
  }

  &-back {
    position: fixed;
    left: 20px;
    top: 20px;
    height: 30px;
    width: 30px;
    z-index: 101;
  }

  &__table-code {
    position: fixed;
    right: 16px;
    top: 20px;
    width: 24px;
    z-index: 101;
  }

  &-paging {
    position: absolute;
    top: 5px;
    width: 100%;
    z-index: 101;
    @include flex(row, center, center);

    &__page {
      width: 100%;
      height: 3px;
      border-radius: 15px;
      background-color: rgba(255, 255, 255, 0.5);
      backdrop-filter: blur(10px);
      margin: 0 2px;

      &:last-child {
        margin-right: 10px;
      }
      &:first-child {
        margin-left: 10px;
      }
      &.-active {
        background-color: rgba(255, 255, 255, 0.9);
      }
    }
  }
  &-slider {
    width: 100%;
    height: 100%;
    position: relative;
    transition: height 0.2s linear;
    will-change: height;

    &.-short {
      height: 50%;
      transition: height 0.2s linear;

      .dish-info {
        bottom: 30px !important;
      }

      .s-category-dishes-slider__nav {
        height: calc(100% - 110px);
      }
    }
    &__item {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      background-position: 50% 50%;
      background-size: cover;
      background-repeat: no-repeat;

      &.-active {
        opacity: 1;
      }

      &.slider-item {
        .category-name {
          position: absolute;
          left: 10px;
          top: 100px;
          width: 90%;
          text-align: left;
          @include font(25, 25, bold);
          color: $white;
          text-shadow: 1px 1px #000;
          z-index: 2;
        }

        .backdrop-image {
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 1;
          background: rgba(0, 0, 0, 0.1);
        }

        .dish-info {
          position: absolute;
          bottom: 100px;
          left: 10px;
          right: 63px;
          z-index: 2;
          @include flex(column-reverse, flex-start, flex-start);

          &-price {
            color: $white;
            @include font(24, 29, 500);
            text-shadow: 1px 1px #000;
            margin-top: 5px;

            span {
              @include font(15, 24, 500);
              margin-left: 10px;
            }
          }
          &-title {
            color: $white;
            @include font(24, 29, 500);
            text-align: left;
            text-shadow: 1px 1px #000;
            margin-top: 5px;
          }

          &-icon {
            position: fixed;
            z-index: 102;
            right: 17px;
            bottom: 134px;
          }

          &-back {
            width: 48px;
            height: 48px;
            background: rgba(0, 0, 0, 0.5);
            background-size: 24px 24px;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            border-radius: 50%;
            backdrop-filter: blur(10px);
            position: absolute;
            z-index: 102;
            bottom: 0;
            left: calc(100% + 5px);
            transform: rotate(-90deg);
            // transform: rotate(-90deg);
          }

          &-stop {
            position: absolute;
            width: 100vw;
            height: 50vh;
            left: -10px;
            bottom: -30px;
            background: rgba(0, 0, 0, 0.7);
            @include flex(column, center, center);

            .stop-text {
              @include font(24, 27, bold);
              margin-top: 22px;
              color: $white;
            }
          }

          &-stop-large-image {
            @include pos-centered;
            position: fixed;
          }

          &-stop-large-text {
            @include pos-centered;
            position: fixed;
            color: $white;
            top: calc(50% + 55px);
          }

          &-rate {
            @include flex(row, flex-start, center);
            margin-top: 12px;

            &__star {
              margin-right: 4px;
              user-select: none;
            }

            &__posts {
              @include font(17, 20, normal);
              color: $white;
              margin-left: 12px;
            }
          }
        }
      }
    }

    &__nav {
      position: absolute;
      top: 0;
      z-index: 2;
      width: 50%;
      height: calc(100% - 180px);

      &.-prev {
        left: 0;
      }
      &.-next {
        right: 0;
      }
    }
  }

  .-swiped-up {
    position: fixed;
    bottom: 50%;
    left: 0;
    z-index: 99999;
    transform: translateY(0px);

    &.-animate-swipe {
      transform: translateY(-50vh);
      transition: transform 0.35s linear;
    }
  }
}
</style>