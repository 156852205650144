<template>
  <div class="qr-presets__wrapper">
    <div class="qr-presets__top-price">
      <span class="qr-presets__top-title">{{ topPriceTitle }}</span>
      <span class="qr-presets__top-amount"
        >{{ currencySymbol }} {{ topPriceAmount }}</span
      >
    </div>
    <div class="qr-presets">
      <div class="qr-presets__header">
        <h3 class="qr-presets__header-text">{{ title }}</h3>
        <img
          class="qr-presets__header-image"
          width="30px"
          height="30px"
          src="/img/buttonClose.png"
          srcset="/img/buttonClose@3x.png 3x /img/buttonClose@2x.png 2x /img/buttonClose.png 1x"
          @click="close()"
        />
      </div>
      <div v-if="$slots.actions" class="qr-presets__actions">
        <slot name="actions" />
      </div>
      <div class="qr-presets__body">
        <div v-if="presetsTitle" class="qr-presets__presets-title">
          {{ presetsTitle }}
        </div>
        <div class="qr-presets__presets">
          <div
            v-for="(preset, index) in presets"
            :key="preset.title"
            :class="[
              'qr-presets__preset',
              currentPresetIndex === index && 'qr-presets__preset--active',
            ]"
            @click="selectPreset(index)"
          >
            <div class="qr-presets__persents">{{ preset.title }}</div>
            <div class="qr-presets__amount">
              {{ currencySymbol }} {{ preset.value }}
            </div>
          </div>
        </div>
        <input
          v-model="inputAmount"
          class="qr-presets__input"
          :placeholder="$t('qrOrder.otherAmount')"
          type="tel"
          disabled
        />
        <div role="button" class="qr-presets__action" @click="selectAmount">
          OK
        </div>
      </div>
    </div>
    <div v-if="loading" class="qr-presets__loader">
      <Loader center />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    currencySymbol: {
      type: String,
      default: '',
    },
    presets: {
      type: Array,
      default: () => [],
    },
    presetsTitle: {
      type: String,
      default: '',
    },
    topPriceTitle: {
      type: String,
      default: '',
    },
    topPriceAmount: {
      type: [String, Number],
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentPresetIndex: 1,
      inputAmount: '',
    }
  },
  watch: {
    inputAmount(value) {
      if (value && this.currentPresetIndex > -1) {
        this.currentPresetIndex = -1
      }
    },
  },
  methods: {
    selectPreset(index) {
      this.inputAmount = ''
      this.currentPresetIndex = index
    },
    selectAmount() {
      this.$emit(
        'select',
        this.inputAmount
          ? Number(this.inputAmount)
          : this.presets[this.currentPresetIndex].value
      )
      this.inputAmount = ''
    },
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
@import './styles/qrPresetDrawer';
</style>
