<template>
  <div class="qr-order">
    <QrOrderBill
      v-show="isShowPayment"
      :items="items"
      :currencySymbol="currencySymbol"
    />
    <div class="qr-order__list">
      <QrOrderLitItem
        v-for="item in items"
        :key="item.uid"
        :item="item"
        :currencySymbol="currencySymbol"
        :isSelected="checkIsItemSelected(item.uid)"
        :isSplitMode="isSplitMode"
        @select="onItemSelect(item.uid)"
      />
    </div>
    <div v-if="isShowPayment" class="qr-order__actions">
      <QrOrderEmail v-if="isEmailShow" v-model="emailData" />
      <div class="qr-order__actions-buttons">
        <div
          role="button"
          :class="['qr-order__action', isPayButtonDisabled && 'qr-order__action--disabled']"
          @click="onPayClick"
        >
          {{ $t('qrOrder.toPay') }}
        </div>
        <div
          v-if="isSplitMode || isEmailShow"
          role="button"
          class="qr-order__action"
          @click="cancelAll"
        >
          {{ $t('qrOrder.toCancel') }}
        </div>
        <div
          v-else
          role="button"
          class="qr-order__action"
          @click="showSplitDrawer"
        >
          {{ $t('qrOrder.splitBill') }}
        </div>
      </div>
    </div>
    <QrOrderTips
      v-if="isTipsDrawerOpen"
      :currencySymbol="currencySymbol"
      :itemsToPay="itemsToPay"
      :placeUid="placeUid"
      :checkinUid="activeCheckinUid"
      @tipsDrawerClose="closeTipsDrawer"
    />
    <QrOrderBillSplit
      v-if="isSplitDrawerOpen"
      :currencySymbol="currencySymbol"
      :billAmount="calculatedUnpaid.total"
      @splitApply="splitApplyHandler"
      @startSplitMode="startSplitModeHandler"
      @splitDrawerClose="closeSplitDrawer"
    />
  </div>
</template>

<script>
import { CURRENCIES } from '@/consts'
import QrOrderBill from './QrOrderBill'
import QrOrderLitItem from './QrOrderLitItem'
import QrOrderTips from './QrOrderTips'
import QrOrderBillSplit from './QrOrderBillSplit'
import QrOrderEmail from './QrOrderEmail'
import payMixin from '@/mixins/payMixin'
import {ordersPayNew as ordersPayNewApi} from '@/init/api'

export default {
  mixins: [payMixin],
  components: {
    QrOrderBill,
    QrOrderLitItem,
    QrOrderTips,
    QrOrderBillSplit,
    QrOrderEmail,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    placeUid: {
      type: String,
      required: true,
    },
    userUid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isTipsDrawerOpen: false,
      isSplitDrawerOpen: false,
      isSplitMode: false,
      isEmailShow: false,
      selectedItems: [],
      emailData: {
        email: '',
        isAgreeLicence: true,
        isAgreeService: true,
      },
      tipsAmount: 0
    }
  },
  computed: {
    activeCheckin() {
      return this.$checkins?.[0]
    },
    activeCheckinUid() {
      return this.activeCheckin?.uid
    },
    currencySymbol() {
      return CURRENCIES[this.activeCheckin?.currency || 'USD']
    },
    isShowPayment() {
      return true || !!Number(this.leftToPay)
    },
    itemsToPay() {
      const { selectedItems, items } = this

      if (!selectedItems.length) {
        return items
      }

      return items.filter((item) => selectedItems.includes(item.uid))
    },
    isPayButtonDisabled() {
      return this.isSplitMode && !this.selectedItems.length
    }
  },
  methods: {
    checkIsItemSelected(uid) {
      return this.selectedItems.includes(uid)
    },
    showSplitDrawer() {
      this.isSplitDrawerOpen = true
    },
    closeSplitDrawer() {
      this.isSplitDrawerOpen = false
    },
    splitApplyHandler(amount) {
      this.closeSplitDrawer()
    },
    startSplitModeHandler() {
      this.isSplitMode = true
      this.closeSplitDrawer()
    },
    onItemSelect(uid) {
      if (this.checkIsItemSelected(uid)) {
        this.selectedItems = this.selectedItems.filter(
          (itemId) => itemId !== uid
        )
      } else {
        this.selectedItems.push(uid)
      }
    },
    showTipsDrawer() {
      this.isTipsDrawerOpen = true
    },
    closeTipsDrawer(tipsAmount = 0) {
      this.isTipsDrawerOpen = false
      this.isEmailShow = true
      this.tipsAmount = tipsAmount
      // this.$emit('pay')
    },
    isEmailValid(email) {
      return email && this.$utils.validateEmail(email)
    },
    async getPaymentLink(paymentType, email) {
      try {
        const {info: {confirmation_url}} = await ordersPayNewApi(
          this.placeUid, 
          this.itemsToPay.map((item) => item.uid), 
          paymentType, 
          this.userUid, 
          this.tipsAmount,
          email,
        )
        return {paymentUrl: confirmation_url}
      } catch (error) {
        return {error}
      }
    },
    async initPay() {
      if (!this.isEmailValid(this.emailData.email)) {
        // todo: Add alert
        console.error('Email is not valid!')
        return
      }
      const paymentType = await this.$parent.getPaymentType()
      if (!paymentType) {
        return
      }
      this.$store.dispatch('places/showLoader', true)
      const {paymentUrl, error} = await this.getPaymentLink(paymentType, this.emailData.email)
      window.newPayWindow = window.open("/loader.html", "_blank")
      this.$parent.confrmationUrlHandler(paymentUrl, paymentType, error)
      this.$store.dispatch('places/hideLoader')
      this.cancelAll();
    },
    onPayClick() {
      if (this.isEmailShow) {
        this.initPay()
        return
      }
      this.showTipsDrawer()
    },
    cancelSplitMode() {
      this.isSplitMode = false
      this.selectedItems = []
    },
    cancelPayment() {
      this.isEmailShow = false
    },
    cancelAll() {
      this.cancelSplitMode()
      this.cancelPayment()
    },
    
    async getUserEmail() {
      this.emailData.email = await window.ordersStore.getUserEmail()
    }
  },
  mounted() {
    this.getUserEmail()
  }
}
</script>

<style lang="scss">
.qr-order {
  background: $black !important;

  &__list {
    @include flex(column, flex-start, center);
    gap: 4px;
    width: 100%;
  }

  &__actions {
    position: fixed;

    bottom: 42px;
    left: 16px;
    right: 16px;
    z-index: 2;
  }

  &__actions-buttons {
    background: rgba(51, 51, 51, 1);
    border-radius: 24px;
    padding: 2px;
    gap: 7px;
    height: 48px;
    @include flex(row, flex-start, center);
  }

  &__action {
    width: 100%;
    border-radius: 24px;
    height: 100%;
    background: $white;
    color: $black;
    @include flex(row, center, center);
    @include font(15, 18, 600);
    text-transform: uppercase;

    &--hidden {
      width: 0;
      visibility: hidden;
    }

    &:last-child {
      background: rgba(255, 255, 255, 0.1);
      color: $white;
    }

    &--disabled {
      opacity: 0.3;
      pointer-events: none;
    }
  }
}
</style>
