import axios from 'axios'
import { API_URL } from './utils'

/**
 *
 * @param {string} checkinUid
 * @param {string} paymentType
 * @param {string} paymentSourceUid
 * @returns { Promise }
 */
export async function postCheckinPay(
  checkinUid,
  paymentType,
  paymentSourceUid
) {
  const postPayload = {
    checkin_uid: checkinUid,
    payment_type: paymentType,
  }
  if (paymentSourceUid) {
    postPayload['payment_source_uid'] = paymentSourceUid
  }

  try {
    const {
      data: { error_code: errorCode, description, payload },
    } = await axios.post(API_URL('/checkin/pay'), postPayload)

    if (errorCode) {
      return { error: { code: errorCode, description } }
    }
    return { confirmationUrl: payload?.confirmation_url ?? '' }
  } catch (error) {
    const status = error.response.status
    if (status === 401) {
      return { error: { status: 401 } }
    }
    throw Error(error)
  }
}

/**
 *
 * @param {string} checkinUid
 * @param {string} placeUid
 * @param {number} tipPercent
 * @returns { Promise }
 */
export async function setTipPercent(
  checkinUid,
  placeUid,
  userUid,
  tipPercent,
) {
  try {
    const {
      data: { error_code: errorCode, description },
    } = await axios.post(API_URL(`/checkin/setTipPercent`), {
      checkin_uid: checkinUid,
      place_uid: placeUid,
      user_uid: userUid,
      tip_percent: tipPercent,
    })

    if (errorCode) {
      return { error: { code: errorCode, description } }
    }
    return
  } catch (error) {
    const status = error.response.status
    if (status === 401) {
      return { error: { status: 401 } }
    }
    throw Error(error)
  }
}
